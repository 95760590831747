// .vertical-slider {
//   position: relative;
//   overflow: hidden;
//   height: 7rem; /* Adjust this value to match the height of your text */
// }

// .vertical-slider-item {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   opacity: 0;
//   transition: transform 0.4s, opacity 0.4s;
//   transform: translateY(-100%);
// }

// .vertical-slider-item.visible {
//   opacity: 1;
//   transform: translateY(0);
//   z-index: 1;
// }

// .vertical-slider-item:not(.visible) + .vertical-slider-item {
//   transform: translateY(100%);
// }

// .vertical-slider-item.visible + .vertical-slider-item {
//   opacity: 0;
//   transform: translateY(-100%);
//   z-index: 2;
// }

// .textContainer {
//   max-width: 1080px;
//   width: 100%;
//   margin: auto;
// }

// .home {
//   height: 100vh;
//   background-image: url("images/bg.jpg");
//   background-size: cover;
//   background-position: center;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 50px 15px;
//   position: relative;
// }
// .home::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.8);
//   z-index: 1;
// }

// .home .home-text {
//   text-align: center;
//   position: relative;
//   z-index: 2;
// }
// .home .home-text p {
//   margin: 0;
//   overflow: hidden;
// }
// .home .home-text p span {
//   font-weight: 700;
//   display: inline-block;
//   line-height: 46px;
//   display: none;
// }

.animate-text .text-in {
  display: block;
  animation: textIn 0.5s ease;
  // animation-timing-function: cubic-bezier(0.27, 2.38, 0.37, -1.55);
  // animation-timing-function: cubic-bezier(0, -1.68, 1, 0.7);
}
.animate-text span.text-out {
  animation: textOut 0.8s ease;
  // animation-timing-function: cubic-bezier(0.27, 2.38, 0.37, -1.55);
  // animation-timing-function: cubic-bezier(0, -1.68, 1, 0.7);
}
@keyframes textIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes textOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

// --webkit clip class for 2nd section text
.gradient-text-class {
  -webkit-background-clip: text;
}
