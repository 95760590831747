@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #a530d0;
  --secondary-color: #9c32f9;
  // --bg-primary: #ffffff;
  --bg-primary: #151515;
  --bg-gradient-primary: linear-gradient(
    135deg,
    #4b31cf -9.71%,
    #a530d0 114.47%
  );
  --primary-heading: #30004a;
  scroll-behavior: smooth;

  // font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}

input,
textarea {
  outline: none;
}
.border-gradient-primary {
  border-image: linear-gradient(135deg, #4b31cf -9.71%, #a530d0 114.47%);
}

.gradient-hero-home {
  background-color: hsla(289, 63%, 48%, 1);
  background-image: radial-gradient(
      at 93% 85%,
      hsla(262, 100%, 56%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 7% 91%, hsla(273, 100%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 8%, hsla(283, 62%, 50%, 1) 0px, transparent 50%),
    radial-gradient(at 92% 14%, hsla(289, 84%, 64%, 1) 0px, transparent 50%);
}

.text-gradient-primary {
  background: linear-gradient(
    91deg,
    #a841f9 0%,
    #9f55fa 25.13%,
    #8c80fb 36.6%,
    #7da3fd 47.25%,
    #75b5fe 57.08%,
    #70befe 67.73%,
    #68d2fe 80.29%,
    #63ddff 90.4%,
    #61e1ff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-secondary {
  background: linear-gradient(
    270deg,
    #a841f9 0%,
    #9f55fa 6.75%,
    #8c80fb 23.62%,
    #7da3fd 41.63%,
    #70befe 58.5%,
    #68d2fe 76.5%,
    #63ddff 94.5%,
    #61e1ff 112.5%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-tertiary {
  background: linear-gradient(
    91deg,
    #a841f9 0.99%,
    #9f55fa 9.3%,
    #8c80fb 30.06%,
    #7da3fd 52.21%,
    #70befe 72.98%,
    #68d2fe 95.13%,
    #63ddff 117.28%,
    #61e1ff 139.43%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes textGradient {
  0% {
    background-position: left;
  }
  25% {
    background-position: top;
  }
  50% {
    background-position: right;
  }
  75% {
    background-position: bottom;
  }

  100% {
    background-position: left;
  }
}
